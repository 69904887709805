import React from "react";

import useMusicPlayer from "./useMusicPlayer";

import logo from "../../cover.jpg";
import tinyviolin from "../../tinyviolin.jpg";
import testimonial1 from "../../testimonial1.png";

import Grid from "@material-ui/core/Grid";

import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import song1 from "../../media/violinplay_lisztcomprehension.wav";
import song2 from "../../media/violinplay_mergeconflict.wav";
import song3 from "../../media/violinplay_foreverforloops.wav";
import song4 from "../../media/violinplay_superclass.wav";
import song5 from "../../media/violinplay_functioncalling.wav";
import song6 from "../../media/violinplay_callbackhell.wav";
import song7 from "../../media/violinplay_adagioforreversestrings.wav";
import song8 from "../../media/violinplay_awalkthroughthebinarytreeforest.wav";
import song9 from "../../media/violinplay_whatisthis.wav";
import song10 from "../../media/violinplay_androidwaltz.wav";

import { useWindowSize } from "react-use";
import Confetti from "react-confetti";

import Secret from "./Secret";
import StickyFooter from "./StickyFooter";
import Credits from "./Credits";
import Icons from "./Icons";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";

import "./App.css";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "30px",
    width: "100%",
    textAlign: "left",
    marginBottom: "1em",
    fontSize: "1.3em"
  },
  paper: {
    padding: theme.spacing(1)
  },
  typography: {
    fontFamily: ["Quicksand"].join(",")
  },
  cursive: {
    fontFamily: "'Mansalva', cursive"
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    flexBasis: "33.33%",
    flexShrink: 0,
    textAlign: "left",
    wordWrap: "break-word",
    fontFamily: "Quicksand"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.text.secondary,
    textAlign: "left",
    fontFamily: "Quicksand"
  },
  grow: {
    flexGrow: 1
  },
  panel: {
    padding: "1em"
  },
  details: {
    alignItems: "center"
  },
  column: {
    flexBasis: "33.33%",
    padding: "1em",
    width: "100%"
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2)
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  }
}));

const messages = [
  {
    id: 1,
    title: "Liszt Comprehension ",
    subheader: "Inspired by Liszt. Mostly piano. Short and sweet.",
    media: song1,
    description: `Some programming languages allow
    you to create a list using a special
    construct called a list comprehension.
    
    The beginning is evocative of La Campanella
    by Liszt, but then goes off
    into its own tangent.
    
    I really enjoyed composing this one.`
  },
  {
    id: 2,
    title: "Merge conflict ⭐",
    subheader:
      "My second favorite. Imagine a monster ambling through the forest and making friends with the animals in the end. ",
    media: song2,
    description: `Merge conflicts happen when a
    piece of newly committed code
    clashes with previously
    commited code.
    
    But in the end, the programmer
    resolves the conflicts. That's why
    the song ends on a bright
    note.`
  },
  {
    id: 3,
    title: "Forever For Loops",
    subheader: "A pure violin loop with a hip-hop vibe.",
    media: song3,
    description: `A "for" loop repeats itself
    until a condition
    set by the programmer is met.
    
    Hope you like this little melody! I've already used this in a recent conference vlog.`
  },
  {
    id: 4,
    title: "Superclass",
    subheader: "Most likely to sound like a superhero theme.",
    media: song4,
    description: `In object-oriented programming, a superclass is a class from which other classes inherit code.`
  },
  {
    id: 5,
    title: "Function Calling",
    subheader: "Initially simple, but then the phrasing becomes beautiful.",
    media: song5,
    description: `A function is
    block of code that can accept
    inputs and returns an output.
    In order to
    run a function after it is
    defined, it needs to be
    "called" or "invoked."
    
    In this piece, you'll hear several
    chord changes which reflect
    different invocations of the
    same function.
    
    My brother thinks this sounds like
    a song from Epic Battle Fantasy.`
  },
  {
    id: 6,
    title: "Callback Hell",
    subheader:
      "Not death metal, but hellish in the classical sense. A pure violin loop.",
    media: song6,
    description: `The
    melody gets jumbled together, but somehow it starts sounding like Bach.`
  },
  {
    id: 7,
    title: "Adagio for Reverse Strings",
    subheader: "Dancing medieval courtiers? (Another pure violin loop.)",
    media: song7,
    description: `Asking
    a programmer to write some code
    that reverses a string
    (e.g. turn the word "cat" into "tac") is
    a common interview question. There are several ways to solve
    this problem. At the end of the song, the person
    finally reaches
    a lightbulb moment.`
  },
  {
    id: 8,
    title: "A Walk Through the Binary Tree Forest",
    subheader:
      "The very first song I composed with the loop pedal. Pure violin + loop in one take.",
    media: song8,
    description: `Walking through a forest can be...
    tangly. Does this sound like traffic in a city to you?`
  },
  {
    id: 9,
    title: "What Is This",
    subheader: "The weirdest song (IMO) I've composed. Electronica.",
    media: song9,
    description: `I still ask myself: what is 'this'. The title doubles as a JavaScript joke.`
  },
  {
    id: 10,
    title: "Android Waltz ⭐",
    subheader: "My favorite. Imagine robots dancing. Electronica.",
    media: song10,
    description: `The very last song I composed for this album. Also, let me know if you can guess the one pecularity about this song.`
  }
];

const Home = () => {
  const classes = useStyles();
  const { width, height } = useWindowSize();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { wizardStatus } = useMusicPlayer();

  return (
    <div className="App {classes.root}">
      <nav></nav>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h2 className={classes.cursive}>Featured: Android Waltz</h2>
        <audio controls src={song10} type="audio/wav">
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      </header>
      <main>
        {wizardStatus ? (
          <>
            <Paper className={classes.root}>
              <Confetti width={width} height={height} />
              <h2>
                Thank you for your support!{" "}
                <span role="img" aria-label="party emoji">
                  🎉
                </span>{" "}
                You've accessed the easter egg part of the site!{" "}
              </h2>
              <p>
                To listen to and download a track, open up each song below and
                click on the three vertical dots next to each audio container.
              </p>
              <p>
                If you have a subscription to{" "}
                <a
                  href="https://open.spotify.com/album/3Dw1bhaOQxGiAD9IUt5grJ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Spotify
                </a>
                ,{" "}
                <a
                  href="https://music.apple.com/us/album/violin-play/1480584867?uo=4&app=itunes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Itunes
                </a>
                ,{" "}
                <a
                  href="https://play.google.com/store/music/album/Linda_Peng_Violin_Play?id=Blaludkrqdh5epciqi77kzoq6tu"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google Play Music
                </a>
                , or{" "}
                <a
                  href="http://www.amazon.com/gp/product/B07Y41PWRM"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Amazon Music
                </a>
                , you can also listen to the album there.{" "}
              </p>
              <Icons />
            </Paper>
          </>
        ) : (
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Secret />
              <Icons />
            </Grid>
            <Grid item md={12}>
              <Paper style={{ padding: "30px" }}>
                <h2>
                  Responses to violin.play() <i className="fas fa-heart"></i>
                </h2>
                <img src={testimonial1} alt="Testimony" width="80%" />
              </Paper>
            </Grid>
          </Grid>
        )}
        {messages.map(message => {
          return (
            <ExpansionPanel
              expanded={expanded === `panel1${message.id}`}
              onChange={handleChange(`panel1${message.id}`)}
              className={classes.panel}
              key={message.id}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  #{message.id} {message.title}
                </Typography>

                <Typography className={classes.secondaryHeading}>
                  {message.subheader}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.details}>
                <div className={classes.column}></div>
                <div className={classes.column}>
                  {wizardStatus ? (
                    <audio controls="controls">
                      <source src={message.media} type="audio/wav" />
                      Your browser does not support the <code>audio</code>{" "}
                      element.
                    </audio>
                  ) : (
                    <>
                      <Secret />
                      <Typography>
                        No secret code?{" "}
                        <a href="mailto:lindatheviolinist@gmail.com">
                          Email me
                        </a>
                        , or listen here:
                      </Typography>
                      <Icons />
                    </>
                  )}
                </div>
                <div className={clsx(classes.column, classes.helper)}>
                  <Typography variant="caption">
                    <Typography>{message.description}</Typography>
                  </Typography>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
        <section className={classes.grow}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Paper className={classes.root}>
                <figure className="swing">
                  <img
                    src={tinyviolin}
                    alt="Thank you violin pic"
                    width="200"
                  />
                </figure>
                <h1>Thank you for supporting my music!</h1>

                <p>
                  When I fall in love with a song, I tend to listen to it on
                  repeat. (And then make a{" "}
                  <a
                    href="https://youtube.com/lpnotes"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-youtube"></i>
                  </a>
                  {""}{" "}
                  <a
                    href="https://www.youtube.com/watch?v=3ZlzsFt4jxI"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    violin cover
                  </a>{" "}
                  of it.)
                </p>

                <p>
                  My hope is that some of these songs from{" "}
                  <strong>violin.play()</strong> sound good enough to listen to
                  on repeat too -- or as background music, while you're working
                  on{" "}
                  <span role="img" aria-label="paint emoji">
                    🎨
                  </span>
                  something creative or solving a particularly difficult
                  problem.
                </p>
                <p>
                  {" "}
                  <i className="fas fa-heart"></i> Linda
                </p>
              </Paper>
              <Paper className={classes.root}>
                <h2>Special thanks to</h2>
                <ul className="special">
                  <li>
                    Friends, family, and strangers who helped get the word out
                    during the Kickstarter campaign, and for being so supportive
                    of this random musical endeavor
                  </li>
                  <li>
                    My brother for listening to my drafts with a musical ear,
                    and giving feedback on clips that did and didn't work
                  </li>
                  <li>
                    My husband for his support, encouragment to make more
                    original music, and most of the funnier song titles
                  </li>
                  <li>
                    My mom, a professional violinist, for teaching me to be
                    musical (and for all the{" "}
                    <span role="img" aria-label="violin emoji">
                      🎻
                    </span>
                    lessons)
                  </li>
                </ul>
              </Paper>
              {wizardStatus && (
                <Paper>
                  <iframe
                    width="100%"
                    height="411"
                    src="https://www.youtube.com/embed/jNjXtvV_WiU"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    title="Easter egg video"
                  ></iframe>
                </Paper>
              )}
            </Grid>
            <Grid item lg={6}>
              <Credits />
            </Grid>
          </Grid>{" "}
        </section>
      </main>
      <StickyFooter />
    </div>
  );
};

export default Home;
