import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "20px",
    width: "100%",
    textAlign: "left",
    fontSize: "1.3em"
  },
  paper: {
    width: "100%"
  }
}));

export default function Credits() {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <h1>Acknowledgements</h1>
      <Grid container spacing={2}>
        <Grid item lg={6}>
          <p>
            <a
              href="https://https://www.kickstarter.com/projects/violinplay/violinplay/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-kickstarter"></i>
            </a>{" "}
            Kickstarter and{" "}
            <a
              href="https://www.patreon.com/lpnotes"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-patreon"></i>
            </a>{" "}
            Patreon backers:
          </p>
          <ul className="credits">
            <li>
              Daisy Thrash <i className="fab fa-patreon"></i>
            </li>
            <li>
              Hannan Ali <i className="fab fa-patreon"></i>{" "}
              <i className="fas fa-trophy gold"></i>
            </li>
            <li>
              Tina Ye <i className="fab fa-patreon"></i>{" "}
              <i className="fas fa-trophy gold"></i>
            </li>
            <li>
              Anbuselvan Periannan <i className="fab fa-patreon"></i>{" "}
              <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy gold"></i>
            </li>
            <li>
              Sabrina Liao <i className="fab fa-patreon"></i>{" "}
              <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy gold"></i>
            </li>
            <li>
              Yu-Chang Chang <i className="fab fa-patreon"></i>
            </li>
            <li>
              Katie Barzee <i className="fab fa-patreon"></i>{" "}
              <i className="fas fa-trophy silver"></i>
            </li>
            <li>
              Laura Hummel <i className="fab fa-patreon"></i>{" "}
              <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy silver"></i>
            </li>
            <li>
              Emily <i className="fab fa-patreon"></i>
            </li>
            <li>
              Edlyn Yuen <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Leonard Bogdonoff <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy gold"></i>
            </li>
            <li>
              Wailin Wong <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy bronze"></i>
            </li>
            <li>
              The Creative Fund by BackerKit{" "}
              <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Jed Schmidt <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Kirk Love <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Russ Eby <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy bronze"></i>
            </li>
            <li>
              Gary Chou <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Fred Wilson <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Anne Libby <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Brendan Schlagel <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy bronze"></i>
            </li>
            <li>
              Ian E <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy bronze"></i>
            </li>
            <li>
              Heather Lin <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy bronze"></i>
            </li>

            <li>
              Linda Yi <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy bronze"></i>
            </li>
            <li>
              Bryant T. <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              JT Kaufman <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy bronze"></i>
            </li>
            <li>
              Dhaval Tanna <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Olivia Brundage <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy bronze"></i>
            </li>
            <li>
              Shelley <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Sebastian Gallese <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Mel <i className="fab fa-kickstarter"></i>
            </li>
          </ul>
        </Grid>
        <Grid item lg={6}>
          <ul className="credits">
            <li>
              GangQing Yao <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy gold"></i>
            </li>
            <li>
              Natalie Macaruso<i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy bronze"></i>
            </li>
            <li>
              wolf <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Belinda <i className="fab fa-kickstarter"></i>{" "}
            </li>

            <li>
              Patti Shin <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              이지형 <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Kimberly Muñoz <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy bronze"></i>
            </li>
            <li>
              Irene Jia <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy bronze"></i>
            </li>
            <li>
              {" "}
              Ben Bowler <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              {" "}
              Julian Johannesen <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Nabil Boutaleb <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Steven Lenkowsky <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy gold"></i>
            </li>
            <li>
              {" "}
              Quincy Larson <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Sharon <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy silver"></i>
            </li>
            <li>
              Ellen Chisa <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Michael Patrick <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Roberto <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Jenn Wong <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              {" "}
              Tasia Kimball <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy bronze"></i>
            </li>
            <li>
              Daniel Pyrathon <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy silver"></i>
            </li>
            <li>
              Matthew Simon Cavalletto <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Yi Zhu <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy gold"></i>
            </li>
            <li>
              Maureen Robinson<i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy silver"></i>
            </li>
            <li>
              Tieg Zaharia <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Buster Benson <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy bronze"></i>
            </li>
            <li>
              Muhammed <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy bronze"></i>
            </li>
            <li>
              Sean Yeh <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Isabel Yap <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Scott Moon <i className="fab fa-kickstarter"></i>
            </li>
            <li>
              Betsy Cannon <i className="fab fa-kickstarter"></i>{" "}
              <i className="fas fa-trophy bronze"></i>
            </li>
            <li>
              Mayene de La Cruz <i className="fab fa-kickstarter"></i>
            </li>
          </ul>
        </Grid>
      </Grid>
    </Paper>
  );
}
