import React from "react";
import Typography from "@material-ui/core/Typography";
import spotify from "../../icons8-spotify-100.png";
import itunes from "../../icons8-itunes-100.png";
import googlePlay from "../../icons8-google-play-64.png";
import amazon from "../../icons8-amazon-100.png";

export default function Icons() {
  return (
    <>
      <Typography variant="h5" component="h2" gutterBottom>
        <a
          href="https://open.spotify.com/album/3Dw1bhaOQxGiAD9IUt5grJ"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          <img src={spotify} alt="spotify" width="64" />
        </a>

        <a
          href="https://music.apple.com/us/album/violin-play/1480584867?uo=4&app=itunes"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={itunes} alt="itunes" width="64" />
        </a>

        <a
          href="https://play.google.com/store/music/album/Linda_Peng_Violin_Play?id=Blaludkrqdh5epciqi77kzoq6tu"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={googlePlay} alt="googlePlay" width="64" />
        </a>
        <a
          href="http://www.amazon.com/gp/product/B07Y41PWRM"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={amazon} alt="amazon" width="64" />
        </a>
      </Typography>
    </>
  );
}
