import { useContext } from "react";
import { AuthContext } from "./AuthContext";

const useMusicPlayer = () => {
  const [state, setState] = useContext(AuthContext);
  function confirmWizardStatus(status) {
    setState(state => ({ ...state, wizardStatus: status }));
  }
  function confirmLoading(status) {
    setState(state => ({ ...state, loading: status }));
  }
  return {
    confirmWizardStatus,
    confirmLoading,
    loading: state.loading,
    wizardStatus: state.wizardStatus
  };
};

export default useMusicPlayer;
