import React from "react";

import useMusicPlayer from "./useMusicPlayer";
import axios from "axios";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 10),
    width: "100%",
    textAlign: "left"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  button: {
    marginTop: "10px",
    background: "#0dc82f",
    "&:hover": { background: "#09a825" }
  }
}));

const Secret = () => {
  const classes = useStyles();

  const [code, setCode] = React.useState("");
  const [identity] = React.useState(
    window.location.search.split("=")[1] || "test"
  );
  const [codeError, setCodeError] = React.useState(false);

  const handleCode = event => {
    setCode(event.target.value);
  };

  const { loading, confirmWizardStatus, confirmLoading } = useMusicPlayer();

  const handleSubmit = e => {
    e.preventDefault();

    confirmLoading(true);
    axios
      .post(`https://lpnotes-album-staging.herokuapp.com/api/login`, {
        username: identity,
        password: code
      })
      .then(res => {
        confirmLoading(false);
        confirmWizardStatus(true);
        setCodeError(false);
      })
      .catch(error => {
        confirmLoading(false);
        setCodeError(true);
        //console.log(error);
      });
  };
  return (
    <div>
      <form
        className={classes.container}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          id="outlined-name"
          label="Secret code"
          className={classes.textField}
          onChange={handleCode}
          margin="normal"
          variant="outlined"
          error={codeError}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.button}
        >
          Access music
        </Button>
      </form>
      {loading && <LinearProgress variant="query" />}
    </div>
  );
};

export default Secret;
